$primary: #1a2f5a;
$info: #1ba8b5;
$warning: #ecab20;

@import "~bootstrap/scss/mixins/banner";
@include bsBanner("");

// scss-docs-start import-stack
// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

$custom-colors: (
  "antropomedia-red": #9e2063,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack

.login {
  &.container-fluid {
    .top {
      &:before {
        transform: rotate(45deg);
        background: var(--bs-antropomedia-red);
      }
      &:after {
        transform: rotate(135deg);
        background: $warning;
      }
    }
    .bottom {
      &:before {
        transform: rotate(-45deg);
        background: $info;
      }
      &:after {
        transform: rotate(-135deg);
        background: $primary;
      }
    }
    .top,
    .bottom {
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 200vmax;
        height: 200vmax;
        top: 50%;
        left: 50%;
        margin-top: -100vmax;
        transform-origin: 0 50%;
        transition: all 0.5s cubic-bezier(0.445, 0.05, 0, 1);
        z-index: 10;
        opacity: 0.65;
        transition-delay: 0.2s;
      }
    }
    .row.justify-content-center > * {
      opacity: 0;
      transition: all 0.5s cubic-bezier(0.445, 0.05, 0, 1);
      transition-delay: 0s;
    }
    &:hover,
    &:active {
      .row.justify-content-center > * {
        opacity: 1;
        transition-delay: 0.2s;
        z-index: 11;
      }
      .top,
      .bottom {
        &::before,
        &::after {
          margin-left: 40vmin;
          transform-origin: -40vmin 50%;
          transition-delay: 0s;
        }
      }
    }
  }
}
#cookies {
  &-INSTAGRAM,
  &-TIKTOK {
    height: 132px;
  }
}
